import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ApiCommonService } from 'src/app/service/api-common.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { CommonService } from 'src/app/service/common.service';
import { LocalstoregeService } from 'src/app/service/localstorege.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ClipboardService } from 'ngx-clipboard';
@Component({
  selector: 'app-work-order-completed',
  templateUrl: './work-order-completed.component.html',
  styleUrls: ['./work-order-completed.component.css']
})
export class WorkOrderCompletedComponent implements OnInit {

  constructor(
    private router:Router,
    public apiservice: ApiCommonService,
    private toastr: ToastrService,
    private localStore:LocalstoregeService,
    private commonservice:CommonService,
    private deviceService: DeviceDetectorService,
    private _clipboardService: ClipboardService,
  ) { 
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }
  deviceInfo:any = null;
  iOS:any = ['IOS','iOS','ios','Mac','MAC','mac'];
  isMacFlag:boolean = false;
  copyURLText:string = '';
  showLoader: boolean = false;
  perPage: number = environment.perPagePagination;
  totalItems: number = 0;
  showBoundaryLinks: boolean = true;
  public current_page: number = 1;
  type: boolean = true;
  isPush: boolean = true;
  listingData: any = [];
  showToast: boolean = false;
  pendingWorkOrderNumber: number = 0;
  completedWorkOrderNumber: number = 0;
  showBack:boolean = true;
  isBackConfirm:boolean =false;

  ngOnInit() {
    this.showToast = false;
    this.loadPendingCompleteCount();
    this.loadPendingWorkOrderData();
     //detect device
     if(this.iOS.includes(this.deviceInfo.os)){
      this.isMacFlag = true;
    }
    console.log('isMacFal',this.isMacFlag,this.deviceInfo);
  }

  gotoURL(str:string){
    if(str){
      this.router.navigate([str]);
    }
  }
  //API CAll
  onScroll(){
    console.log('onscroll called')
    this.current_page = this.current_page+ 1;  
    this.isPush = false;
    this.loadPendingWorkOrderData();
  }

  getOrderNumber() {
    let orderNumber = (document.getElementById('searchOrder') as HTMLInputElement).value;
    console.log(document.getElementById('searchOrder'), orderNumber)
    this.loadPendingWorkOrderData(orderNumber);
  }

  loadPendingCompleteCount() {
    let params = {};
    this.apiservice.getNumberOfPendingCompleteCount(params).then(res => {
      console.log('res==>', res)
      if (res.success) {
        // console.log('success data=',res.data.pneding)
        // console.log('success data=',res.data.completed)
        this.pendingWorkOrderNumber = res.data.pending_inprogress;
        this.completedWorkOrderNumber = res.data.completed
      } else {
        // if(this.showToast){
        //   this.showToast = false;
        //   this.toastr.error(res.message,'Error', {
        //     timeOut: 3000,
        //   });
        // }
      }
    }).catch(err => {
      this.showLoader = false;

    });
  }

  //load pending workOrder Data
  loadPendingWorkOrderData(orderNumber: any = null) {
    this.showLoader = true;
    let param = {
      status: 4,
      status1: 6,
      order_number: orderNumber,
      page: this.current_page,
      perpage: this.perPage
    }
    this.apiservice.getWorkOrderListWithDetails(param).then(res => {
      this.showLoader = false;
      console.log('pending list==', res);
      if (res.success) {
        console.log('inside success pending list==', res);
        this.totalItems = res.data && res.data.length && res.data[0].total;
        if (this.isPush) {
          this.listingData = res.data[0].data;
        } else {
          if (res.data && res.data.length && res.data[0].data) {
            res.data[0].data.forEach(item => {
              this.listingData.push(item);
            });
          }
        }

        // this.loadlocalData();
        // if(this.showToast){
        //   this.showToast = false;
        //   this.toastr.success(res.message, 'Success', {
        //     timeOut: 3000,
        //   });
        // }
      } else {
        // this.loadlocalData();
        // if(this.showToast){
        //   this.showToast = false;
        //   this.toastr.error(res.message,'Error', {
        //     timeOut: 3000,
        //   });
        // }
      }
    }).catch(err => {
      this.showLoader = false;
      // this.loadlocalData();
    });
  }

  viewDetailsWorkOrder(item:any) {
    console.log('view Work Order', item.id);
    this.router.navigate(['view-work-order', item.id]);
  }
  instantMsg(item:any){
    this.router.navigate(['workorder-message', item.id,item.me_id, item.title_officer]);
  }
  //download pdf
  downloadPDF(item:any){
    
    let isLogIn = this.localStore.getItem('status')?this.localStore.getItem('status'):'';
    console.log('go for the downloadPDF',item);
    if(isLogIn == 'ONLINE'){
      if(item.id.toString().length == 13){
        // this.router.navigate(['edit-report',item.id]);
        console.log('go for the downloadPDF');
        Swal.fire({
          title: 'You are offline',
          text: "You can't export unsynchronized reports",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Okay',
          cancelButtonText:'No'
        })
      }else{
        this.showLoader = true;
        if(this.isMacFlag){
          this._clipboardService.destroy();
        }
        this.apiservice.exportpdf({id:item.report_id}).then(res => {
          this.showLoader = false;
          if(res.success){
                if(res.data && res.data.url && res.data.url_normal && res.data.static_url){
                  if(this.isMacFlag){
                    // window.open(res.data.url);
                    // this._clipboardService.copyFromContent(res.data.url);
                    // this.localStore.setItem('pdf_Data',JSON.stringify(res.data));
                    // this.router.navigate(['pdf']);
                    // return;
                    this.copyURLText = res.data.url;
                    document.getElementById('openModal').click();
                    /*
                    let returnType =  this.copyText(res.data.static_url);//this._clipboardService.copyFromContent(res.data.url);
                    console.debug(returnType);
                    
                    if(returnType){
                      this.toastr.success('Please paste the copied link in your browser to download the pdf','', {
                        timeOut: 3000,
                      });
                    }else{
                      this.toastr.error('Your device is not support to copy clipboard new','', {
                        timeOut: 3000,
                      });
                    }
                    */
                    // this.copyText(res.data.static_url);

                  }else{
                    this.toastr.success(res.message,'', {
                      timeOut: 3000,
                    });
                    //window.open(res.data.url_normal);
                    // console.log();
                    // this.localStore.setItem('pdf_Data',JSON.stringify(res.data));
                    // this.router.navigate(['pdf']);
                    // return;
                    window.open(res.data.url);
                  }
                  
                }
          }else{
            this.toastr.error(res.message,'Error', {
              timeOut: 3000,
            });
          }
        }).catch(err => {
          this.showLoader = false;
        });
      }
    }else{
      Swal.fire({
        title: 'You are offline',
        text: "Please connect to network in order to export reports",
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Okay',
        cancelButtonText:'No'
      })
    }

  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  iphoneDownloadPDF(item: any) {
    console.log('iphone download')
    let isLogIn = this.localStore.getItem('status') ? this.localStore.getItem('status') : '';
    // console.log('go for the downloadPDF',item);
    if (isLogIn == 'ONLINE') {
      if (item.id.toString().length == 13) {
        // this.router.navigate(['edit-report',item.id]);
        // console.log('go for the downloadPDF');
        Swal.fire({
          title: 'You are offline',
          text: "You can't export unsynchronized reports",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Okay',
          cancelButtonText: 'No'
        })
      } else {
        this.showLoader = true;
        if (this.isMacFlag) {
          this._clipboardService.destroy();
        }
        this.apiservice.exportpdf({ id: item.report_id }).then(res => {
          this.showLoader = false;
          if (res.success) {
            if (res.data && res.data.i_phone && res.data.url_normal && res.data.static_url) {
              if (this.isMacFlag) {
                // prev code
                // this.copyURLText = res.data.url;
                // document.getElementById('openModal').click();

               var blob = this._base64ToArrayBuffer(res.data.i_phone);
                console.log(blob);
                var blob1 = new Blob([blob], { type: "application/pdf" });
                var url = window.URL.createObjectURL(blob1);
                var filename = "orderpdf.pdf";
                var a = document.createElement("a");
                a.href = url;
                a.download = filename;
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                  a.remove();
                }, 1000);

              } else {
                this.toastr.success(res.message, '', {
                  timeOut: 3000,
                });
                //window.open(res.data.url_normal);
                // console.log();
                // this.localStore.setItem('pdf_Data',JSON.stringify(res.data));
                // this.router.navigate(['pdf']);
                // return;
                window.open(res.data.url);
              }

            }
          } else {
            this.toastr.error(res.message, 'Error', {
              timeOut: 3000,
            });
          }
        }).catch(err => {
          this.showLoader = false;
        });
      }
    } else {
      Swal.fire({
        title: 'You are offline',
        text: "Please connect to network in order to export reports",
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Okay',
        cancelButtonText: 'No'
      })
    }

  }
  
}
